<template>
  <section
    class="preview-template-view"
    v-loading="loading"
    :element-loading-text="loadingText"
  >
    <div class="template-body-section">
      <el-form v-if="interactiveQuestionsMode" @submit.native.prevent>
        <div style="width: 100%; overflow-x: hidden">
          <div v-if="isAllQuestionsAnswered" style="text-align: center">
            <h2>
              You have answered all questions. Please click on '{{
                buttonText
              }}'.
            </h2>
            <el-button
              type="primary"
              plain
              size="mini"
              :loading="buttonLoading"
              @click="goToReview"
              >Stay & Review</el-button
            >
            <el-button
              @click="emitFinishEvent"
              type="primary"
              size="mini"
              :loading="buttonLoading"
              >{{ buttonText }}</el-button
            >
          </div>
          <preview-interactive-template-form
            v-else
            :fieldsData="fieldsData"
            :isExecute="isExecute"
            :form="form"
            :interactiveQuestionsModeType="interactiveQuestionsModeType"
            :dataId="entityDataId"
            :isFormFormbuilder="isFormFormbuilder"
            @onFilledQuestion="updateInteractiveQuestionFillingStatus"
          ></preview-interactive-template-form>
        </div>
      </el-form>
      <el-form v-else @submit.native.prevent>
       <el-scrollbar wrap-style="overflow-y: auto;">
        <div class="form-builder" ref="formBuilder" :style="getStylediv">
          <div class="paragraphScroll">
            <div
              v-if="
                fieldsData &&
                fieldsData.length &&
                !refreshTemplate &&
                !hasRepeatableTemplate
              "
              class="form-fields-holder"
            >
              <div
                v-for="(field, index) of fieldsData"
                :key="index"
                :style="getExistedStyle(field) || getStyle(field, index)"
              >
                <HorizontalLineExecute
                  :data="field"
                  v-if="field.input_type === 'HORIZONTAL_LINE'"
                  :form="form"
                  :is-view="!isExecute"
                  :label="getFieldLabelFromRules(field)"
                ></HorizontalLineExecute>
                <DateExecute
                  :data="field"
                  v-if="field.input_type === 'DATE' && !checkhideFields(field)"
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :label="getFieldLabelFromRules(field)"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter"
                ></DateExecute>
                <TimeExecute
                  :data="field"
                  v-if="field.input_type === 'TIME' && !checkhideFields(field)"
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :label="getFieldLabelFromRules(field)"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter"
                ></TimeExecute>
                <TimeRangeExecute
                  :data="field"
                  v-if="
                    field.input_type === 'TIME_RANGE' && !checkhideFields(field)
                  "
                  :form="form"
                  :is-view="!isExecute"
                  :label="getFieldLabelFromRules(field)"
                  :hasLabel="hasLabel"
                ></TimeRangeExecute>
                <DateTimeExecute
                  :data="field"
                  v-if="
                    field.input_type === 'DATE_TIME' && !checkhideFields(field)
                  "
                  :form="form"
                  :is-view="!isExecute"
                  :label="getFieldLabelFromRules(field)"
                  :hasLabel="hasLabel"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter"
                ></DateTimeExecute>
                <DateTimeRangeExecute
                  :data="field"
                  v-if="
                    field.input_type === 'DATE_TIME_RANGE' &&
                    !checkhideFields(field)
                  "
                  :form="form"
                  :is-view="!isExecute"
                  :label="getFieldLabelFromRules(field)"
                  :hasLabel="hasLabel"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter"
                ></DateTimeRangeExecute>
                <DateRangeExecute
                  :data="field"
                  v-if="
                    field.input_type === 'DATE_RANGE' && !checkhideFields(field)
                  "
                  :form="form"
                  :is-view="!isExecute"
                  :label="getFieldLabelFromRules(field)"
                  :hasLabel="hasLabel"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter"
                ></DateRangeExecute>
                <FileExecute
                  :data="field"
                  v-if="field.input_type === 'FILE' && !checkhideFields(field)"
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :label="getFieldLabelFromRules(field)"
                  :checkIsDisabled="checkIsDisabled(field)"
                  :isExecute="true"
                ></FileExecute>
                <MultiLineTextExecute
                  :data="field"
                  v-if="
                    (field.input_type === 'MULTI_LINE_TEXT' ||
                      field.input_type === 'RICH_TEXT') &&
                    !checkhideFields(field)
                  "
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :label="getFieldLabelFromRules(field)"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter"
                ></MultiLineTextExecute>
                <SingleLineTextExecute
                  :data="field"
                  v-if="
                    (field.input_type === 'SINGLE_LINE_TEXT' ||
                      field.input_type === 'EMAIL' ||
                      field.input_type === 'PASSWORD' ||
                      field.input_type === 'MASKED' ||
                      field.input_type === 'HYPER_LINK') &&
                    !checkhideFields(field)
                  "
                  :form="form"
                  :is-view="!isExecute"
                  :label="getFieldLabelFromRules(field)"
                  :hasLabel="hasLabel"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter"
                ></SingleLineTextExecute>
                <MultiSelectExecute
                  :data="field"
                  v-if="
                    field.input_type === 'MULTI_SELECT' &&
                    !checkhideFields(field)
                  "
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :label="getFieldLabelFromRules(field)"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter"
                ></MultiSelectExecute>
                <SelectExecute
                  :data="field"
                  v-if="
                    field.input_type === 'SELECT' && !checkhideFields(field)
                  "
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :label="getFieldLabelFromRules(field)"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter"
                ></SelectExecute>
                <YesOrNoExecute
                  :data="field"
                  v-if="
                    field.input_type === 'YES_OR_NO' && !checkhideFields(field)
                  "
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :label="getFieldLabelFromRules(field)"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter"
                ></YesOrNoExecute>
                <NumberExecute
                  :data="field"
                  v-if="
                    field.input_type === 'NUMBER' && !checkhideFields(field)
                  "
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :label="getFieldLabelFromRules(field)"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter"
                ></NumberExecute>
                  <PhoneCountryCodeExecute
                  :data="field"
                  v-if="
                    field.input_type === 'PHONE_COUNTRY_CODE' &&
                    !checkhideFields(field)
                  "
                  :form="form"
                  :is-view="!isExecute"
                  :checkIsDisabled="checkIsDisabled(field)"
                  :hasLabel="hasLabel"
                  :label="getFieldLabelFromRules(field)"
                  @applyFormRules="rulesEventEmitter"
                ></PhoneCountryCodeExecute>
                <CurrencyExecute
                  :data="field"
                  v-if="
                    field.input_type === 'CURRENCY' && !checkhideFields(field)
                  "
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :label="getFieldLabelFromRules(field)"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter"
                ></CurrencyExecute>
                <HeadingExecute
                  :data="field"
                  v-if="
                    field.input_type === 'HEADING' && !checkhideFields(field)
                  "
                  :form="form"
                  :label="getFieldLabelFromRules(field)"
                  :is-view="!isExecute"
                ></HeadingExecute>
                <CheckBoxExecute
                  :data="field"
                  v-if="
                    field.input_type === 'CHECKBOX' && !checkhideFields(field)
                  "
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :fieldsData="getFieldsData"
                  :label="getFieldLabelFromRules(field)"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter"
                  :singleCompanyTemplateCheckBoxArray="
                    singleCompanyTemplateCheckBoxArray
                  "
                ></CheckBoxExecute>
                <RadioExecute
                  :data="field"
                  v-if="field.input_type === 'RADIO' && !checkhideFields(field)"
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :fieldsData="getFieldsData"
                  :label="getFieldLabelFromRules(field)"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter"
                ></RadioExecute>
                <RadioGroupExecute
                  :data="field"
                  v-if="
                    field.input_type === 'RADIO_BUTTON_GROUP' &&
                    !checkhideFields(field)
                  "
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :fieldsData="getFieldsData"
                  :label="getFieldLabelFromRules(field)"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter"
                ></RadioGroupExecute>
                <ESignatureExecute
                  :data="field"
                  v-if="field.input_type === 'SIGNATURE'"
                  :form="form"
                  :hasLabel="hasLabel"
                  :label="getFieldLabelFromRules(field)"
                ></ESignatureExecute>
                <GlobalVariableExecute
                  :data="field"
                  v-if="
                    field.input_type === 'GLOBAL_VARIABLE' &&
                    !checkhideFields(field)
                  "
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :label="getFieldLabelFromRules(field)"
                  :fieldsData="fieldsData"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter"
                ></GlobalVariableExecute>
                <EntityVariableExecute
                  :data="field"
                  v-if="
                    field.input_type === 'ENTITY_VARIABLE' &&
                    !checkhideFields(field)
                  "
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :fieldsData="fieldsData"
                  :label="getFieldLabelFromRules(field)"
                  :checkIsDisabled="checkIsDisabled(field)"
                  :class="'dataTable' + index"
                  @entityDataUpdated="setDataToEntityVariables"
                  @clearEntityFields="unsetEntityVariables"
                  @applyFormRules="rulesEventEmitter"
                  @updateTableData="updateTableFieldData"
                  @onNewRowAdded="updateTopForBelowElements"
                ></EntityVariableExecute>

                <EntityExecute
                  :data="field"
                  v-if="
                    field.input_type === 'ENTITY' && !checkhideFields(field)
                  "
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :fieldsData="fieldsData"
                  :label="getFieldLabelFromRules(field)"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @entityDataUpdated="setDataToEntityVariables"
                  @clearEntityFields="unsetEntityVariables"
                  @applyFormRules="rulesEventEmitter"
                ></EntityExecute>

                <ParagraphExecute
                  :data="field"
                  v-if="field.input_type === 'PARAGRAPH'"
                  :form="form"
                  :label="getFieldLabelFromRules(field)"
                  :is-view="!isExecute"
                ></ParagraphExecute>
                <SingleLineContentExecute
                  :data="field"
                  v-if="field.input_type === 'SINGLE_LINE_CONTENT'"
                  :form="form"
                  :is-view="!isExecute"
                  :label="getFieldLabelFromRules(field)"
                  :hasLabel="hasLabel"
                ></SingleLineContentExecute>
                <AuthorizedSignatureExecute
                  :data="field"
                  v-if="field.input_type === 'AUTHORIZED_SIGNATURE'"
                  :form="form"
                  :label="getFieldLabelFromRules(field)"
                  :is-view="!isExecute"
                ></AuthorizedSignatureExecute>
                <!-- IMage html formula -->
                <FormulaExecute
                  :data="field"
                  v-if="
                    field.input_type === 'FORMULA' && !checkhideFields(field)
                  "
                  :form="form"
                  :is-view="!isExecute"
                  :entityDataId="entityDataId"
                  :label="getFieldLabelFromRules(field)"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter"
                ></FormulaExecute>
                <ActionButtonExecute
                  :data="field"
                  v-if="
                    field.input_type === 'ACTION_BUTTON' &&
                    !checkhideFields(field)
                  "
                  :form="form"
                  :is-view="!isExecute"
                  :workflowDataId="entityDataId"
                  :label="getFieldLabelFromRules(field)"
                  :fieldsData="fieldsData"
                  @applyFormRules="rulesEventEmitter"
                  :checkIsDisabled="checkIsDisabled(field)"
                ></ActionButtonExecute>
                <ImageExecute
                  :data="field"
                  v-if="field.input_type === 'IMAGE'"
                  :form="form"
                  :is-view="!isExecute"
                  :label="getFieldLabelFromRules(field)"
                  :hasLabel="hasLabel"
                ></ImageExecute>

                <VideoExecute
                  :data="field"
                  v-if="field.input_type === 'VIDEO'"
                  :form="form"
                  :is-view="!isExecute"
                  :label="getFieldLabelFromRules(field)"
                  :hasLabel="hasLabel"
                ></VideoExecute>
                <HtmlExecute
                  :data="field"
                  v-if="field.input_type === 'HTML_CONTENT'"
                  :form="form"
                  :label="getFieldLabelFromRules(field)"
                  :is-view="!isExecute"
                ></HtmlExecute>
                <ListExecute
                  :data="field"
                  v-if="field.input_type === 'LIST' && !checkhideFields(field)"
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :label="getFieldLabelFromRules(field)"
                  @applyFormRules="rulesEventEmitter"
                  :checkIsDisabled="checkIsDisabled(field)"
                ></ListExecute>
                <AggregateFunctionExecute
                  :data="field"
                  v-if="
                    field.input_type === 'AGGREGATE_FUNCTION' &&
                    !checkhideFields(field)
                  "
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :fieldsData="fieldsData"
                  :label="getFieldLabelFromRules(field)"
                  :workflowDataId="entityDataId"
                  @applyFormRules="rulesEventEmitter"
                  :checkIsDisabled="checkIsDisabled(field)"
                ></AggregateFunctionExecute>
                <QuestionExecute
                  :data="field"
                  v-if="field.input_type === 'QUESTION'&&
                    !checkhideFields(field)"
                  :form="form"
                  :is-view="!isExecute"
                  :label="getFieldLabelFromRules(field)"
                  @applyFormRules="rulesEventEmitter"
                  :checkIsDisabled="checkIsDisabled(field)"
                ></QuestionExecute>
                <CheckBoxGroupExecute
                  :data="field"
                  v-if="
                    field.input_type === 'CHECKBOX_GROUP' &&
                    !checkhideFields(field)
                  "
                  :form="form"
                  :hasLabel="hasLabel"
                  :isView="!isExecute"
                  :label="getFieldLabelFromRules(field)"
                  :fieldsData="getFieldsData"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter"
                ></CheckBoxGroupExecute>
                <AutoIncrementExecute
                  :data="field"
                  v-if="field.input_type === 'AUTO_INCREMENT_NUMBER' &&  !checkhideFields(field)"
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :entityId="entityId"
                  :label="getFieldLabelFromRules(field)"
                  :isHideField="checkhideFields(field)"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter"
                ></AutoIncrementExecute>
              <WeekDaysExecute
                :data="field"
                v-if="field.input_type == 'WEEKDAYS' && !checkhideFields(field)"
                :form="form"
                :hasLabel="hasLabel"
                :is-view="!isExecute"
                :label="getFieldLabelFromRules(field)"
                :checkIsDisabled="checkIsDisabled(field)"
                @applyFormRules="rulesEventEmitter"
              >
              </WeekDaysExecute>
              <StarRatingExecute
                :data="field"
                v-if="
                  field.input_type === 'STAR_RATING' &&
                  !checkhideFields(field)
                "
                :form="form"
                :hasLabel="hasLabel"
                :isView="!isExecute"
                :fieldsData="getFieldsData"
                :label="getFieldLabelFromRules(field)"
                :checkIsDisabled="checkIsDisabled(field)"
                @applyFormRules="rulesEventEmitter"
              ></StarRatingExecute>

              <PaymentExecute
                  :data="field"
                  v-if="field.input_type === 'PAY_BUTTON'"
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel"
                  :checkIsDisabled="checkIsDisabled(field)"
                  :fieldsData="getFieldsData"
                  :entityId="entityId"
                  :entityDataId="entityDataId"
                  :currentEntity="currentEntity"
                  :currentStep="currentStep"
                  :type="type"
                  :label="getFieldLabelFromRules(field)"
                  @applyFormRules="rulesEventEmitter"
                ></PaymentExecute>
              <DataTableExecute
                    :class="'dataTable'+index"
                    ref="dataTable"
                    :data="field"
                    v-if="field.input_type === 'DATA_TABLE' &&
                  !checkhideFields(field)"
                    :form="form"
                    :hasLabel="hasLabel"
                    :isView="!isExecute"
                    :fieldsData="fieldsData"
                    :entityDataId="entityDataId"
                    @updateTableData="updateTableFieldData"
                    :label="getFieldLabelFromRules(field)"
                    @onNewRowAdded="updateTopForBelowElements(index)"
                    :checkIsDisabled="checkIsDisabled(field)"
                    @applyFormRules="rulesEventEmitter"
                  ></DataTableExecute>
                  <EntityTableExecute
                  :class="'dataTable'+index"
                    ref="dataTable"
                    :data="field"
                    v-if="field.input_type === 'ENTITY_TABLE'"
                    :form="form"
                    :hasLabel="hasLabel"
                    :isView="!isExecute"
                    :fieldsData="fieldsData"
                    :entityDataId="entityDataId"
                    :label="getFieldLabelFromRules(field)"
                    @updateTableData="updateTableFieldData"
                    @onNewRowAdded="updateTopForBelowElements(index)">

                  </EntityTableExecute>
                  <PayVariableExecute
                    :data="field"
                    v-if="field.input_type === 'PAYMENT_VARIABLE'"
                    :form="form"
                    :hasLabel="hasLabel"
                    :fieldsData="fieldsData"
                    :isView="!isExecute"
                    :checkIsDisabled="checkIsDisabled(field)"
                    :label="getFieldLabelFromRules(field)"
                    @entityDataUpdated="setDataToEntityVariables"
                    @clearEntityFields="unsetEntityVariables"
                    @applyFormRules="rulesEventEmitter"
                  ></PayVariableExecute>
                  <ConcatenateExecute
                    :data="field"
                    v-if="
                      field.input_type === 'CONCATENATE' && !checkhideFields(field)
                    "
                    :form="form"
                    :fieldsData="getFieldsData"
                    :hasLabel="hasLabel"
                    :label="getFieldLabelFromRules(field)"
                    :checkIsDisabled="checkIsDisabled(field)"
                    @applyFormRules="rulesEventEmitter"
                  >
                </ConcatenateExecute>
                <AudioExecute  :data="field"
                    v-if="
                      field.input_type == 'AUDIO' && !checkhideFields(field)
                    "
                    :form="form"
                    :is-view="!isExecuteCopy"
                    :hasLabel="!hasLabel"
                    :colorFields="colorFields"
                    :label="getFieldLabelFromRules(field)"
                    :checkIsDisabled="checkIsDisabled(field)"
                    @applyFormRules="rulesEventEmitter"></AudioExecute>
                <CaptchaExecute
                  :data="field"
                  v-if="
                   field.input_type === 'CAPTCHA' &&
                   !checkhideFields(field)
                  "
                  :form="form"
                  :hasLabel="hasLabel"
                  :isView="!isExecute"
                  :fieldsData="getFieldsData"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter"
              ></CaptchaExecute>

              <QRExecute
                  :data="field"
                  v-if="
                   field.input_type === 'QR' &&
                   !checkhideFields(field)
                  "
                  :form="form"
                  :hasLabel="hasLabel"
                  :isView="!isExecute"
                  :fieldsData="getFieldsData"
                  :checkIsDisabled="checkIsDisabled(field)"
                  @applyFormRules="rulesEventEmitter"
              ></QRExecute>
              <QRReaderExecute 
              
              :data="field"
              v-if="
               field.input_type === 'QR_READER' &&
               !checkhideFields(field)
              "
              :form="form"
              :hasLabel="hasLabel"
              :isView="!isExecute"
              :fieldsData="getFieldsData"
              :checkIsDisabled="checkIsDisabled(field)"
              @applyFormRules="rulesEventEmitter"
          ></QRReaderExecute>

            </div>
          </div>
          <RepeatableTemplateExecute
            v-if="hasRepeatableTemplate&& !checkhideFields(field)"
            :templateData="templateData"
            :entityRepeatableTemplateData="entityRepeatableTemplateData"
            :baseWidth="currentFormBuilderWidth"
            :form="form"
            :entitiesData="entitiesData"
            :isSubmit="isSubmit"
            :isView="!isExecute"
            :getDefaultDateFormat="getDefaultDateFormat"
            :checkIsDisabled="checkIsDisabled(field)"
            @applyFormRules="rulesEventEmitter"
          />
         </div>
        </div>
       </el-scrollbar> 
      </el-form>
    </div>
  </section>
</template>
<script>
var fieldsList = require("./fields.json");
import { bus } from "../../main";
import { mapGetters } from "vuex";
import TemplateBuilderHelper from "@/mixins/templateBuilderHelper.js";
import dataTableStylesHelper from "@/mixins/dataTableStylesHelper";

export default {
  created() {
    this.getAllFieldList();
    this.setTemplateInfo();
  },
  mixins: [TemplateBuilderHelper, dataTableStylesHelper],
  props: [
    "entityDataId",
    "templateData",
    "workflowDetailsId",
    "isExecute",
    "isFormFormbuilder",
    "form",
    "buttonText",
    "isEntityExecute",
    "isRepeatable",
    "entityId",
    "currentEntity",
    "currentStep","type"
  ],
  async mounted()
   {
    console.log("ROUTES",this.$route)
   },
  computed: {
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    maxHeight() {
      if (this.fieldsData) {
        let max = 500;
        this.fieldsData.forEach((e) => {
          if (e?.y + e?.height >= max) {
            max = e.y + e.height;
          }
        });
        return max;
      }
      return 1000;
    },
    getStylediv() {
      let style = "";
      if (this.interactiveQuestionsMode) {
        style = "";
      } else {
        style =
          style +
          `height: ${this.maxHeight}px !important; margin-bottom: ${
            this.isRepeatable ? 125 : 75
          }px;`;
      }
      if (this.getIsMobile) {
        style = style + "margin-bottom: 1050px;";
      }
      if (!this.isEntityExecute) {
        style = style + "width: 1200px;";
      }
      return style;
    },
    fieldActionsObject() {
      const obj = {};
      this.fieldActions.forEach((action) => {
        obj[action.inputType] = action;
      });
      return obj;
    },
    getFieldsData() {
      this.fieldsData.forEach((f) => {
        if (
          f.input_type == "ACTION_BUTTON" &&
          f.action_button_type === "TEMPLATE"
        ) {
          this.pos.x = f.x;
          this.pos.y = f.y;
          this.pos.height = f.height;
        }
      });
      this.fieldsData.forEach((f) => {
        if (
          f.input_type != "ACTION_BUTTON" &&
          (this.pos.x > 0 || this.pos.y > 0 || this.pos.height > 0)
        ) {
          if (this.pos.y < f.y) {
            if (this.pos.y + this.pos.height + 150 < f.y) {
              return;
            } else {
              f.y = this.pos.y + this.pos.height + 200;
            }
          }
        }
      });
      return this.fieldsData;
    },
    checkApprovalEdit(){
      return this.$route?.query?.up
    },
  },
  methods: {
    getFieldLabelFromRules(field){
      if(this.labelsData && this.labelsData[field.key]){
        return this.labelsData[field.key]
      }
      return field.label
    },
    getExistedStyle(field) {
      return field.style || undefined;
    },
    checkIsDisabled(field) {
      return this.disabledFields.find((e) => e == field.key) ? true : false;
    },
    checkhideFields(field) {
      return this.hideFields.find((e) => e == field.key) ? true : false;
    },
    rulesEventEmitter() {
      this.applyRulesOnFields(this.fieldsData, this.templateRules, this.form);
    },
    goToReview() {
      this.buttonLoading = true;
      this.isAllQuestionsAnswered = false;
      bus.$emit("move-to-question", 0);
    },
    async emitFinishEvent() {
      this.buttonLoading = true;
      await this.$emit(
        "submitForm",
        false,
        this.isRepeatable ? true : false,
        false
      );
      this.isAllQuestionsAnswered = false;
    },
    async setDataToEntityVariables(data, parent, label, changed) {
      let entityId = data?._id ? data._id : null;
      if (label) {
        this.$set(this.form, parent.key + "/name", label);
      }
      this.getFieldsData.forEach((field) => {
        if (
          field.relationship_key == parent.key &&
          (field.field_assignable == "read_only" ||
            !this.form[field.key] ||
            changed)
        ) {
          if (field.global_variable_entity_field) {
            let templateId = "";
            let key = "";
            let value = "";
            let valueName = "";
            if (field.global_variable_entity_field.includes("#")) {
              templateId = field.global_variable_entity_field.split("#")[0];
              key = field.global_variable_entity_field.split("#")[1];
            } else {
              templateId = field.global_variable_entity_field;
            }
            if (parent?.allow_multiple) {
              if (data.length) {
                let dataValues = [],
                  result = 0,
                  min;
                (data || []).forEach((e) => {
                  if (e?.entityData?.[templateId]?.[key]) {
                    if (field.inputType == "DATA_TABLE") {
                      if (e.entityData[templateId][key].length) {
                        e?.entityData[templateId][key].forEach((dt) => {
                          dataValues.push({
                            ...dt,
                            ...{ parentDataId: e._id, parentDataName: e.parentDataName, },
                          });
                        });
                      }
                    } else if (this.isNumber(e.entityData[templateId][key])) {
                      let v = e.entityData[templateId][key];
                      if (
                        field.variable_action == "SUM" ||
                        field.variable_action == "AVERAGE"
                      ) {
                        result = result + parseInt(v);
                      } else if (
                        field.variable_action == "HIGHEST" &&
                        v > result
                      ) {
                        result = parseInt(v);
                      } else if (field.variable_action == "LOWEST") {
                        if (v < min || min == undefined) {
                          min = parseInt(v);
                        }
                      }
                    }
                  }
                });
                if (field.inputType == "DATA_TABLE") {
                  this.$set(this.form, field.key, dataValues);
                } else {
                  if (field.variable_action == "AVERAGE") {
                    let avg = result / data.length;
                    this.$set(this.form, field.key, avg);
                  } else if (field.variable_action == "LOWEST") {
                    this.$set(this.form, field.key, min);
                  } else {
                    this.$set(this.form, field.key, result);
                  }
                }
              }
            } else {
              if (
                data &&
                data?.entityData &&
                data?.entityData[templateId] &&
                data?.entityData[templateId][key]
              ) {
                value = data.entityData[templateId][key];
              }
              if (
                data &&
                data?.entityData &&
                data?.entityData[templateId] &&
                data?.entityData[templateId][key]
              ) {
                value = data.entityData[templateId][key];

                if (field.inputType == "ENTITY") {
                  valueName = data.entityData[templateId][key + "/name"];
                  this.$set(this.form, field.key + "/default", [value]);
                }
              }
              if (valueName) {
                this.$set(this.form, field.key + "/name", valueName);
              }
              if (this.form[field.key]) {
                this.form[field.key] = value;
              } else {
                this.$set(this.form, field.key, value);
              }
            }
          }
        }
      });
      if (!this.form.parent_data) {
        this.form.parent_data = {};
      }
      if (entityId) {
        this.form.parent_data[entityId] = data;
      } else {
        this.form.parent_data = {};
      }
      this.rulesEventEmitter();
    },
    isNumber(value) {
      return typeof value === "number" || !isNaN(parseFloat(value));
    },
    async unsetEntityVariables(parent) {
      this.getFieldsData.forEach((field) => {
        if (field.relationship_key == parent.key) {
          this.$set(this.form, field.key, "");
        }
      });
      // if (!this.form.parent_data) {
      //   this.form.parent_data = {};
      // }

      // if (entityId) {
      //   this.form.parent_data[entityId] = [];
      // }
      this.rulesEventEmitter();
    },
    updateInteractiveQuestionFillingStatus(status) {
      if (!this.entityDataId || !this.getTemplatesData) {
        if (status == true && this.interactiveQuestionsAreDone) {
          this.$emit("allFieldsCompleted", true);
          this.buttonLoading = false;
          this.isAllQuestionsAnswered = true;
        }
        this.interactiveQuestionsAreDone = status;
      }
    },
    calculateRelativeLeft(x) {
      return x;
    },

    calculateRelativeTop(y) {
      return y;
    },
    async getAllFieldList() {
      try {
        // let params = "form=true&content=true"
        // await this.$store.dispatch("standardFields/getFieldLists", params);
        if (fieldsList) {
          //   let dFieldList = this.fieldsList;
          this.fieldActions = [
            ...fieldsList.allFormFields,
            ...fieldsList.allContentFields,
            ...fieldsList.allAdvancedFields,
          ];
        }
      } catch (error) {
        console.log("getAllFieldList",error);
      }
    },
    async setTemplateInfo() {
      this.loading = true;
      await this.setTemplateElements(this.templateData);
      this.oldForm = { ...this.form };
      this.loading = false;
    },
    async setTemplateElements(templateData) {
      this.name = templateData.name;
      this.description = templateData.description;
      this.type = templateData.type;
      this.slug = templateData.slug;
      this.templateStyles = templateData.styles;
      this.interactiveQuestionsMode = templateData.use_interactive_questionare;
      this.interactiveQuestionsModeType =
        templateData.interactive_questionare_type;
      this.storedFormBuilderWidth = templateData.page_base_width;
      // if (this.type == "STANDARD") {
      //   this.showFields = false;
      // } else {
      let defaultSection = templateData.sections.find(
        (x) => x.type == "DEFAULT"
      ); // Todo need to change for multiple sections
      // this.fieldsData = defaultSection.fields;

      // we are sorting all fields depends on x,y cordinates to flow in the form
      defaultSection.fields = defaultSection.fields.sort((a, b) => {
        if (a.y - b.y < 10) {
          return a.x - b.x;
        }
        return a.y - b.y;
      });
      this.templateRules = templateData.rules ? [...templateData.rules] : [];
      this.applyRulesOnFields(this.fieldsData, this.templateRules, this.form);
      this.fieldsData = this.setSizeAndPositionForElements(
        defaultSection.fields
      );
      if (
        templateData &&
        templateData.sections &&
        templateData.sections[0] &&
        templateData.sections[0].checkBoxGroup
      ) {
        this.singleCompanyTemplateCheckBoxArray =
          templateData.sections[0].checkBoxGroup;
      }
      this.fieldsData.map((e) => (e.template_id = templateData?._id));
      this.fieldsData = this.fieldsData.sort((a, b) => a.order - b.order);
      this.repeatableTemplateFields = this.fieldsData.filter(
        (e) =>
          e.input_type == "ACTION_BUTTON" && e.action_button_repeating_template
      );
      defaultSection.fields = defaultSection.fields.sort((a, b) => {
        return a.y - b.y;
      });
      // if (this.interactiveQuestionsMode) {
      //   this.interactiveQuestionsAreDone = true;
      // }
      let lastElement = defaultSection.fields[this.fieldsData.length - 1];

      this.tableStartingPoint = lastElement.y + lastElement.height + 30;

      this.formHeight = lastElement.y + lastElement.height + 200;
      // }
    },
    setSizeAndPositionForElements(elements) {
      let questionIndex = 1;
      return elements.map((field) => {
        field = {
          ...field,
          //...this.getDefaultSizeRangesByFieldType(field.input_type),
        };
        field.x = this.calculateRelativeLeft(field.x);
        field.y = this.calculateRelativeTop(field.y);

        let width = this.calculateRelativeWidth(field.width);
        let height = this.calculateRelativeWidth(field.height);

        // if (width > field.max_width) {
        //   field.width = field.max_width;
        // } else if (width < field.min_width) {
        //   field.width = field.min_width;
        // } else {
        field.width = width;
        // }
        // if (height > field.max_height) {
        //   field.height = field.max_height;
        // } else if (height < field.min_height) {
        //   field.height = field.min_height;
        // } else {
        field.height = height;
        // }
        if (field.type == "FIELD") {
          field.questionIndex = questionIndex;
          questionIndex = questionIndex + 1;
        }
        if (this.entityId) {
          field.parent_entityId = this.entityId;
          field.parent_entityDataId = this.entityDataId; 
        }
        if (field.input_type === "DATA_TABLE") {
          field.data_table_columns = (field.data_table_columns || []).map(e => {
            e.parent_entityId = this.entityId;
            e.parent_entityDataId = this.entityDataId;
            if (this.templateData?._id) {
              e.template_id = this.templateData._id;
            }
            return e;
          });
        }
        if (this.templateData?._id && !field.template_id) {
          field.template_id = this.templateData._id;
        }
        return field;
      });
    },
    calculateRelativeWidth(width) {
      return width;
    },
    getDefaultSizeRangesByFieldType(inputType) {
      if (
        inputType === "SINGLE_LINE_TEXT" ||
        inputType === "PASSWORD" ||
        inputType === "EMAIL" ||
        inputType === "MASKED"
      ) {
        inputType = "SINGLE_LINE_TEXT";
      } else if (inputType === "MULTI_LINE_TEXT" || inputType === "RICH_TEXT") {
        inputType = "SINGLE_LINE_TEXT";
      } else if (
        inputType === "NUMBER" ||
        inputType === "PHONE" ||
        inputType === "FAX" ||
        inputType === "CURRENCY"
      ) {
        inputType = "NUMBER";
      } else if (
        inputType === "DATE" ||
        inputType === "TIME" ||
        inputType === "DATE_TIME"
      ) {
        inputType = "DATE";
      }
      //  else if(
      //   inputType === "DATE_TIME"
      // ){
      //   inputType = "DATE_TIME";
      // }
      let data = this.fieldActionsObject[inputType];
      return {
        min_height: data.min_height,
        min_width: data.min_width,
        max_height: data.max_height,
        max_width: data.max_width,
      };
    },
    updateTopForBelowElements(data) {
      this.fieldsData.forEach((e, index) => {
        if (e.listen_height) {
          if (data) {
            this.$set(
              this.fieldsData[index],
              "style",
              this.getStyle(e, index, data.height)
            );
          } else {
            this.$set(this.fieldsData[index], "style", this.getStyle(e, index));
          }
        }
      });
    },
    updateTableFieldData(details) {
      this.$set(this.form, details.key, details.data);
    },
  },
  data() {
    return {
      loading: false,
      buttonLoading: false,
      loadingText: "",
      interactiveQuestionsMode: false,
      name: "",
      description: "",
      singleCompanyTemplateCheckBoxArray: [],
      templateStyles: "",
      slug: "",
      interactiveQuestionsModeType: "",
      storedFormBuilderWidth: null,
      tableStartingPoint: 0,
      formHeight: 600,
      hasRepeatableTemplate: false,
      fieldsData: [],
      fieldActions: [],
      refreshTemplate: false,
      hideFields: [],
      disabledFields: [],
      pos: {
        x: 0,
        y: 0,
        height: 0,
      },
      hasLabel: true,
      interactiveQuestionsAreDone: false,
      isAllQuestionsAnswered: false,
      templateRules: [],
      colorFields: {}
    };
  },
  components: {
    HorizontalLineExecute: () =>
      import("./formComponentsExecute/HorizontalLineExecute"),
    DateExecute: () => import("./formComponentsExecute/DateExecute"),
    DateTimeExecute: () => import("./formComponentsExecute/DateTimeExecute"),
    DateTimeRangeExecute: () =>
      import("./formComponentsExecute/DateTimeRangeExecute"),
    DateRangeExecute: () => import("./formComponentsExecute/DateRangeExecute"),
    FileExecute: () => import("./formComponentsExecute/FileExecute"),
    MultiLineTextExecute: () =>
      import("./formComponentsExecute/MultiLineTextExecute"),
    SingleLineTextExecute: () =>
      import("./formComponentsExecute/SingleLineTextExecute"),
    MultiSelectExecute: () =>
      import("./formComponentsExecute/MultiSelectExecute"),
    NumberExecute: () => import("./formComponentsExecute/NumberExecute"),
    SelectExecute: () => import("./formComponentsExecute/SelectExecute"),
    YesOrNoExecute: () => import("./formComponentsExecute/YesOrNoExecute"),
    HeadingExecute: () => import("./formComponentsExecute/HeadingExecute"),
    CheckBoxExecute: () => import("./formComponentsExecute/CheckBoxExecute"),
    RadioExecute: () => import("./formComponentsExecute/RadioExecute"),
    ESignatureExecute: () =>
      import("./formComponentsExecute/ESignatureExecute"),
    ParagraphExecute: () => import("./formComponentsExecute/ParagraphExecute"),
    GlobalVariableExecute: () =>
      import("./formComponentsExecute/GlobalVariableExecute"),
    EntityVariableExecute: () =>
      import("./formComponentsExecute/EntityVariableExecute"),
    EntityExecute: () => import("./formComponentsExecute/EntityExecute"),
    SingleLineContentExecute: () =>
      import("./formComponentsExecute/SingleLineContentExecute"),
    AuthorizedSignatureExecute: () =>
      import("./formComponentsExecute/AuthorizedSignatureExecute"),
    ImageExecute: () => import("./formComponentsExecute/ImageExecute"),
    RadioGroupExecute: () =>
      import("./formComponentsExecute/RadioButtonGroupExecute.vue"),
    VideoExecute: () => import("./formComponentsExecute/VideoExecute"),
    FormulaExecute: () => import("./formComponentsExecute/FormulaExecute"),
    ActionButtonExecute: () =>
      import("./formComponentsExecute/ActionButtonExecute"),
    HtmlExecute: () => import("./formComponentsExecute/HtmlExecute"),

    ListExecute: () => import("./formComponentsExecute/ListExecute"),
    AggregateFunctionExecute: () =>
      import("./formComponentsExecute/AggregateFunctionExecute"),
    RepeatableTemplateExecute: () => import("./RepeatableTemplateExecute.vue"),
    PhoneCountryCodeExecute: () =>
      import("./formComponentsExecute/PhoneCountryCodeExecute.vue"),
    CurrencyExecute: () =>
      import("./formComponentsExecute/CurrencyExecute.vue"),
    PreviewInteractiveTemplateForm: () =>
      import("@/components/templates/PreviewInteractiveTemplateForm"),
    QuestionExecute: () => import("./formComponentsExecute/QuestionExecute"),
    TimeExecute: () => import("./formComponentsExecute/TimeExecute"),
    TimeRangeExecute: () =>
      import("./formComponentsExecute/TimeRangeExecute.vue"),
    CheckBoxGroupExecute: () =>
      import("./formComponentsExecute/CheckBoxGroupExecute"),
    AutoIncrementExecute: () =>
      import("./formComponentsExecute/AutoIncrementExecute"),
    WeekDaysExecute: () =>
      import("./formComponentsExecute/WeekDaysExecute.vue"),
    StarRatingExecute: () =>
      import("./formComponentsExecute/StarRatingExecute.vue"),
      PaymentExecute: () =>
      import("./formComponentsExecute/PaymentExecute.vue"),
    DataTableExecute: () => 
      import("./formComponentsExecute/DataTableExecute"),
    PayVariableExecute:()=>import("./formComponentsExecute/PayVariableExecute.vue"),
    ConcatenateExecute: () =>
      import("./formComponentsExecute/ConcatenateExecute"),
    AudioExecute: () =>
      import("./formComponentsExecute/AudioExecute"),
    CaptchaExecute: () =>
      import("./formComponentsExecute/CaptchaExecute"),
    QRExecute:() => import ("./formComponentsExecute/QRExecute"),
    QRReaderExecute:() => import("./formComponentsExecute/QRReaderExecute"),
    EntityTableExecute: () => 
      import("./formComponentsExecute/EntityTableExecute"),
  },
};
</script>
<style lang="scss" scoped>
.preview-template-view {
  .header-action-block {
    margin-right: 10px;
  }
  .template-body-section {

    .el-form{
      height: 80vh;
      max-height: 80vh;
    }
    .form-builder {
      .form-fields-holder {
        @media (max-width: 991.98px) {
          & > div {
            position: relative !important;
            left: 10px !important;
            top: 0px !important;
            margin-top: 30px;
            width: calc(100% - 20px) !important;
            input {
              width: 100%;
            }
            .heading {
              margin-top: 40px !important;
            }
            p {
              width: 100% !important;
            }
          }
        }
      }
    }
  }
  .template-body-section {
    height: 80vh;
    max-height: 80vh;
    .el-scrollbar {
      border: 1px solid #e4e7ec;
      max-height: 80vh;
      height: 80vh;
    }
    margin-top: 10px;
    width: 100%;
    // @media (max-width: 766.98px) {
    //   width: 70%;
    // }
  }

  .template-body-section ::v-deep .el-scrollbar__view {
  /* Your styles for el-scrollbar__view go here */
  overflow-x: hidden;
}
}
.preview-template-view {
  height: 80vh;
  max-height: 80vh;
  margin: 1em 0em;
  h1 {
    margin-bottom: 25px !important;
    font-size: 1.125em;
    font-weight: 500;
  }
  .form-group {
    margin-bottom: 25px;
  }
  .preview-form-header {
    margin-top: 1em;
    margin-left: 10px;
    z-index: 100;
    h1 {
      font-size: 2.1em;
      font-family: "Mulish", sans-serif;
      font-weight: 500;
      letter-spacing: -0.5px;
      color: #1f2f3d;
      margin-bottom: 0.2em !important;
    }
    p {
      margin-top: 0;
      padding: 0.15em 0 1.5em;
      line-height: 1.5 !important;
      color: #5e6d82;
    }
  }
  .header-action-block {
    margin-right: 10px !important;
    .default-button {
      display: block;
      text-transform: uppercase;
      color: #5e6d82;
      min-width: 100px;
      margin: 0.5em 0 0 auto;
    }
  }
  .footer-action-block {
    position: relative;
    margin-bottom: 3em;
    .el-button {
      margin-top: -39px;
      display: block;
      padding: 0.7em 1em;
      margin-right: 10px;
      min-width: 100px;
      font-size: 1em;
    }
    .required-fields-info {
      top: 10px;
      font-size: 14px;
      & > div {
        margin-right: 1.5em;
        border: 1px solid #eeeeee;
        padding: 0.65em 1em;
        border-radius: 4px;
        font-size: 1em;
        letter-spacing: 0.5px;
      }
    }
  }
  .sty {
    margin-top: 500px;
  }
  .form-builder {
    position: relative;
    @media (max-width: 991.98px) {
      width: 100%;
    }
    // width: 1200px;
    // min-height: calc(100vh - 210px) !important;
    // height: 100%;
    border: 1px solid #eeeeee;
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.05);
    .form-fields-holder {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      // height: 100%;

      .each-element img {
        max-width: 100%;
      }
    }
  }
  .paragraphScroll {
    height: auto;
    overflow-y: scroll;
    overflow-x: hidden;
    border-top: 1px ridge;
    border-left: 1px ridge;
    border-bottom: 1px ridge;
    margin-right: 5px;
    width: 100%;
  }
  .paragraphScroll::-webkit-scrollbar {
    width: 0.3em;
  }
  .paragraphScroll::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    background: #e1e1e1;
    -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
  }
}
</style>
